import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Paper, TextField } from '@material-ui/core';
import { IDevice } from 'shared/model/device.model';
import AutoOrderDeviceInfos from './AutoOrderDeviceInfos';
import { DeviceAutoOrder, GroupAutoOrder } from 'shared/model/autoOrder.model';
import DeviceAutoOrders from './DeviceAutoOrders';
import { AutoOrderFormResponse } from './AutoOrderForm';
import { IGroup } from 'shared/model/group.model';

export const TRUCK_QUANTITY_TONS = 31;

const AutoOrderFormTriggeringSetup = ({
  isActiveStep,
  editGroupAutoOrder
}: {
  isNew: boolean;
  editGroupAutoOrder?: GroupAutoOrder;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { watch, register } = useFormContext<AutoOrderFormResponse>();
  const groupId = watch('groupId')?.value;
  const shipTo = watch('shipTo');
  const soldTo = watch('soldTo');
  const group = useSelector(({ group }: IRootState) =>
    group.groups.find(g => g.group_id === groupId)
  );

  const selectedDeviceIds: string[] = watch('selectedDeviceIds');

  const selectedDevices = useSelector(({ devices }: IRootState) =>
    devices.devices.filter(d => selectedDeviceIds.includes(d.device_id))
  );

  useEffect(() => {
    register('autoOrders', {
      validate: (autoOrders: AutoOrderFormResponse['autoOrders']) => {
        if (!isActiveStep) return true;
        // All devices have at least one trigger
        const missingTrigger =
          selectedDeviceIds.some(id => !Object.keys(autoOrders).includes(id)) ||
          Object.values(autoOrders).some(deviceAutoOrders => deviceAutoOrders.length === 0);
        return !missingTrigger;
      }
    });
  }, [register, selectedDeviceIds, isActiveStep]);

  return (
    <>
      <Box p={2}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <TextField
              label={t('plant')}
              value={group?.group_name ?? ''}
              disabled
              placeholder="None"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item>
            <TextField
              label={t('ship_to')}
              value={shipTo || ''}
              disabled
              InputLabelProps={{ shrink: true }}
              placeholder="None"
            />
          </Grid>

          <Grid item>
            <TextField
              label={t('sold_to')}
              value={soldTo || ''}
              disabled
              placeholder="None"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        {selectedDevices.map(device => (
          <DeviceAutoOrderConfiguration
            key={device.device_id}
            device={device}
            group={group}
            editDeviceAutoOrder={editGroupAutoOrder?.device_auto_orders.find(
              (dao: DeviceAutoOrder) => dao.device_id === device.device_id
            )}
          />
        ))}
      </Box>
    </>
  );
};

const DeviceAutoOrderConfiguration = ({
  group,
  device,
  editDeviceAutoOrder
}: {
  group: IGroup;
  device: IDevice;
  editDeviceAutoOrder?: DeviceAutoOrder;
}) => {
  return (
    <Box p={2} maxWidth={960} flexGrow={1} width="100%">
      <Paper elevation={2}>
        <Box display="flex" flexDirection="column">
          <AutoOrderDeviceInfos device={device} editDeviceAutoOrder={editDeviceAutoOrder} />

          <Divider style={{ height: 2 }} />

          <Box p={1}>
            <DeviceAutoOrders device={device} group={group} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AutoOrderFormTriggeringSetup;
