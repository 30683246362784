import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ReactComponent as SiloIconBold } from 'shared/icons/SiloIconBold.svg';
import { TRUCK_QUANTITY_TONS } from './AutoOrderFormTriggeringSetup';
import { DeviceAutoOrder } from 'shared/model/autoOrder.model';

const AutoOrderDeviceInfos = ({
  device,
  editDeviceAutoOrder
}: {
  device: IDevice;
  editDeviceAutoOrder?: DeviceAutoOrder;
}) => {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {/* Device name */}
        <Grid item xs={3}>
          <Typography variant="body1">
            <strong>{device.device_name}</strong>
          </Typography>
        </Grid>

        {/* Capacity */}
        <Grid item xs={3}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" pr={1}>
              <Typography variant="body2" align="right">
                <strong>{t('capacity')}</strong>
              </Typography>
              <Typography variant="body2" align="right">
                {t('number_workspace_filling_unit', { value: device.capa_max })}
              </Typography>
            </Box>

            <SiloIconBold height={30} width={30} color={'#E0E0E0'} />
          </Box>
        </Grid>

        {/* Delivery quantity */}
        <Grid item xs={3}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" pr={1}>
              <Typography variant="body2" align="right">
                <strong>{t('delivery_quantity')}</strong>
              </Typography>
              <Typography variant="body2" align="right">
                {t('one_truck_quantity', { quantity: TRUCK_QUANTITY_TONS })}
              </Typography>
            </Box>
            <Box style={{ fontSize: 30 }} display="flex" alignItems="center">
              <LocalShippingIcon fontSize="inherit" style={{ color: '#E0E0E0' }} />
            </Box>
          </Box>
        </Grid>

        {/* Product */}
        <Grid item xs={3}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" pr={1}>
              <Typography variant="body2" align="right">
                <strong>{t('products')}</strong>
              </Typography>
              <Typography variant="body2" align="right">
                {device.deviceContent?.device_content}
              </Typography>
            </Box>
            <Box style={{ fontSize: 30 }} display="flex" alignItems="center">
              <AssignmentIcon fontSize="inherit" style={{ color: '#E0E0E0' }} />
            </Box>
          </Box>
        </Grid>

        {/* Enabled */}
        {/* {editDeviceAutoOrder && (
          <Grid item xs={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Switch checked={editDeviceAutoOrder.is_active} color="primary" disabled />
            </Box>
          </Grid>
        )} */}
      </Grid>
    </Box>
  );
};

export default AutoOrderDeviceInfos;
