import {
  Box,
  Typography,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Divider
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { AutoOrderFormResponse } from './AutoOrderForm';
import { IDevice } from 'shared/model/device.model';
import { useFormContext } from 'react-hook-form';
import { useAutoOrderTriggerDialog } from './AutoOrderTriggerDialog';
import { sortBy } from 'lodash';
import { Alert } from '@material-ui/lab';
import { IGroup } from 'shared/model/group.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ruleButtons: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

const DeviceAutoOrders = ({ device, group }: { device: IDevice; group: IGroup }) => {
  const { t } = useTranslation();
  const form = useFormContext<AutoOrderFormResponse>();
  const autoOrders = form.watch('autoOrders') ?? {};
  const deviceAutoOrders = autoOrders[device.device_id] ?? [];

  return (
    <>
      {sortBy(deviceAutoOrders, a => {
        const levelTrigger =
          a.data_type === 'missingWeight' ? device.capa_max - (a.max_value ?? 0) : a.min_value ?? 0;
        return -levelTrigger;
      }).map((autoOrder, index) => {
        return (
          <Fragment key={autoOrder.alert_id}>
            <DeviceAutoOrderRule
              autoOrder={autoOrder}
              onUpdate={updatedAutoOrder => {
                form.setValue('autoOrders', {
                  ...autoOrders,
                  [device.device_id]: deviceAutoOrders.map(a =>
                    a.alert_id === autoOrder.alert_id ? updatedAutoOrder : a
                  )
                });
              }}
              onDelete={() => {
                form.setValue('autoOrders', {
                  ...autoOrders,
                  [device.device_id]: deviceAutoOrders.filter(
                    ({ alert_id }) => autoOrder.alert_id !== alert_id
                  )
                });
              }}
            />
            <Divider />
          </Fragment>
        );
      })}

      <DeviceAddAutoOrderRule
        onCreate={autoOrder => {
          form.setValue('autoOrders', {
            ...autoOrders,
            [device.device_id]: [
              ...deviceAutoOrders,
              {
                ...autoOrder,
                alert_name: `AUTO-ORDER ${device.device_name} - ${group.group_name}`
              }
            ]
          });
        }}
      />

      {form.errors.autoOrders && deviceAutoOrders.length === 0 && (
        <Alert severity="error">{t('auto_order_trigger_missing')}</Alert>
      )}
    </>
  );
};

const DeviceAutoOrderRule = ({
  autoOrder,
  onUpdate,
  onDelete
}: {
  autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0];
  onUpdate: (autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0]) => void;
  onDelete: () => void;
}) => {
  const classes = useStyles();

  const { openDialog, dialog } = useAutoOrderTriggerDialog({
    onSubmit: onUpdate,
    ...autoOrder
  });

  return (
    <Box
      p={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="body2">
          {autoOrder.data_type === 'missingWeight' && (
            <Trans
              i18nKey="auto_order_trigger_free_space_exceeds"
              values={{
                truck_count: Number(autoOrder.custom_integration?.nbOfOrder) ?? 1,
                quantity: autoOrder.max_value
              }}
              components={{
                b: <strong />
              }}
            />
          )}

          {autoOrder.data_type === 'level_t' && (
            <Trans
              i18nKey="auto_order_trigger_level_below"
              values={{
                truck_count: Number(autoOrder.custom_integration?.nbOfOrder) ?? 1,
                quantity: autoOrder.min_value
              }}
              components={{
                b: <strong />
              }}
            />
          )}
        </Typography>
      </Box>

      <Box flexGrow={1} />

      <Box className={classes.ruleButtons}>
        <ConfirmDelete
          onConfirm={onDelete}
          objectToReturn={['rule']}
          size="small"
          btnVariant="outlined"
        />

        <Button color="primary" variant="outlined" onClick={openDialog}>
          <EditIcon />
        </Button>
        {dialog}
      </Box>
    </Box>
  );
};

/**
 * Line to add an auto-order rule to a device
 */
const DeviceAddAutoOrderRule = ({
  onCreate
}: {
  onCreate: (autoOrder: AutoOrderFormResponse['autoOrders']['device_id'][0]) => void;
}) => {
  const classes = useStyles();

  const { openDialog, dialog } = useAutoOrderTriggerDialog({
    onSubmit: onCreate
  });

  return (
    <Box
      p={1}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexGrow={1} />

      <Typography variant="body2">
        <Trans
          i18nKey="auto_order_add_trigger_rule"
          components={{
            b: <strong />
          }}
        />
      </Typography>

      <Box className={classes.ruleButtons}>
        <Button color="primary" variant="contained" onClick={openDialog}>
          <AddIcon />
        </Button>
      </Box>

      {dialog}
    </Box>
  );
};

export default DeviceAutoOrders;
