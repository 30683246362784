import React, { useCallback, useMemo, useState } from 'react';
import { useForm, FormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { v4 as uuid } from 'uuid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';
import { nanolikeDataType } from 'shared/model/api.model';
import SelectMax from 'modules/alerts/form/selectMax';
import SelectMin from 'modules/alerts/form/selectMin';
import { CustomIntegration } from 'shared/model/autoOrder.model';

const AVAILABLE_DATA_TYPES: nanolikeDataType[] = [
  'level_t',
  // 'level_percent',
  'missingWeight'
];

type AutoOrderTriggerDialogForm = {
  data_type: 'level_t' | 'missingWeight';
  min_value?: number;
  is_active: boolean;
  max_value?: number;
  nb_of_orders: number;
};

type AutoOrderTriggerDialogValues = {
  alert_id: string;
  is_active: boolean;
  data_type: 'level_t' | 'missingWeight';
  min_value?: number;
  max_value?: number;
  custom_integration: CustomIntegration;
};

type AutoOrderTriggerDialogProps = Partial<AutoOrderTriggerDialogValues> & {
  isOpen: boolean;
  onSubmit: (data: AutoOrderTriggerDialogValues) => void;
  onCancel?: () => void;
};

const AutoOrderTriggerDialog: React.FC<AutoOrderTriggerDialogProps> = ({
  onSubmit,
  onCancel,
  isOpen,
  ...defaultValues
}) => {
  const { t } = useTranslation();
  const form = useForm<AutoOrderTriggerDialogForm>({
    mode: 'onChange',
    defaultValues: {
      data_type: defaultValues.data_type ?? 'missingWeight',
      min_value: defaultValues.min_value,
      max_value: defaultValues.max_value,
      nb_of_orders: defaultValues.custom_integration?.nbOfOrder ?? 1
    }
  });

  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const dataTypesOptions = useMemo(
    () =>
      AVAILABLE_DATA_TYPES.map(item => ({
        label: localizedDataTypeWithUnit(item),
        value: item
      })) as ILabelValueOption<'level_t' | 'missingWeight'>[],
    [localizedDataTypeWithUnit]
  );

  const dataType = form.watch('data_type');

  const onSave = form.handleSubmit(formData => {
    onSubmit({
      alert_id: defaultValues.alert_id ?? uuid(),
      ...formData,
      is_active: defaultValues.is_active ?? true,
      custom_integration: {
        nbOfOrder: Number(formData.nb_of_orders)
      }
    });
  });

  return (
    <Dialog open={isOpen} onClose={onCancel} disablePortal={false}>
      <FormContext {...form}>
        <DialogTitle>{t('auto_order_configure_trigger')}</DialogTitle>

        <DialogContent dividers>
          <FormControl>
            <Controller
              name="data_type"
              control={form.control}
              as={
                <Select>
                  {dataTypesOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>

          {['level_t'].includes(dataType) && <SelectMin fullWidth />}

          {['missingWeight'].includes(dataType) && <SelectMax fullWidth />}

          <FormControl>
            <InputLabel>{t('number_of_orders')}</InputLabel>
            <Controller name="nb_of_orders" control={form.control} as={<Input type="number" />} />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel} startIcon={<CancelIcon />}>
            {t('cancel')}
          </Button>
          <Button onClick={onSave} color="primary" startIcon={<SaveIcon />}>
            {t('save')}
          </Button>
        </DialogActions>
      </FormContext>
    </Dialog>
  );
};

function useAutoOrderTriggerDialog(
  props: Omit<AutoOrderTriggerDialogProps, 'onCancel' | 'isOpen'>
) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const dialog = isOpen ? (
    <AutoOrderTriggerDialog
      {...props}
      isOpen={isOpen}
      onCancel={() => setOpen(false)}
      onSubmit={data => {
        props.onSubmit(data);
        setOpen(false);
      }}
    />
  ) : null;

  return { openDialog, dialog };
}

export { useAutoOrderTriggerDialog };
export default AutoOrderTriggerDialog;
