import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import SelectGroup from 'shared/widgets/form/selectGroup';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoOrderFormResponse } from './AutoOrderForm';
import SelectUsers from 'shared/widgets/form/selectUsers';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import Edit from '@material-ui/icons/Edit';
import CreateOrEditGroup from 'modules/groupsDevices/form/createOrEditGroup';
import validateRequired from 'shared/widgets/form/validateRequired';
import { GroupAutoOrder } from 'shared/model/autoOrder.model';
import { IGroup } from 'shared/model/group.model';
import combineValidates from 'shared/widgets/form/combineValidates';

const AutoOrderFormPlantSelect = ({
  editGroupAutoOrder,
  isNew,
  isActiveStep
}: {
  editGroupAutoOrder?: GroupAutoOrder;
  isNew: boolean;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue, control, watch, register, errors } = useFormContext<AutoOrderFormResponse>();

  useEffect(() => {
    if (editGroupAutoOrder) {
      setValue('groupId', { value: editGroupAutoOrder.group_id });
    }
  }, [editGroupAutoOrder, setValue]);

  const groupId = watch('groupId')?.value;
  const emailNotification = watch('emailNotification');

  useEffect(() => {
    register('emailRecipients', {
      validate: combineValidates(isActiveStep && emailNotification && validateRequired)
    });
  }, [emailNotification, isActiveStep, register]);
  const emailRecipients = watch('emailRecipients');

  const groupAutoOrders = useSelector(({ autoOrder }: IRootState) => autoOrder.groupAutoOrders);
  const filterSelectableGroups = useCallback(
    (groups: IGroup[]) => {
      return groups.filter(group => {
        const alreadyHasAutoOrder = groupAutoOrders.some(
          autoOrder => autoOrder.group_id === group.group_id
        );
        return group.is_poi && (!isNew || !alreadyHasAutoOrder);
      });
    },
    [groupAutoOrders, isNew]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6} lg={4}>
        <SelectGroup
          name={'groupId'}
          required={isNew}
          disabled={!isNew}
          filterGroups={filterSelectableGroups}
          defaultValue={editGroupAutoOrder?.group_id}
        />
        {/* Edit shipTo and soldTo */}
        {groupId && <EditShiptoSoldto groupId={groupId} isActiveStep={isActiveStep} />}

        <Box p={2} />

        {/* Checkbox for email notification */}
        {groupId && (
          <FormControlLabel
            label={t('auto_order_notification')}
            control={
              <Controller
                name="emailNotification"
                control={control}
                defaultValue={false}
                as={<Checkbox color="primary" />}
              />
            }
          />
        )}
        {emailNotification && (
          <SelectUsers
            value={emailRecipients}
            textFieldProps={{
              error: Boolean(errors.emailRecipients),
              helperText: errors.emailRecipients?.message
            }}
            onChange={userIds => {
              setValue('emailRecipients', userIds);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

const EditShiptoSoldto = ({
  groupId,
  isActiveStep
}: {
  groupId: string;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext();
  const group = useSelector(({ group }: IRootState) =>
    group.groups.find(g => g.group_id === groupId)
  );
  const shipTo = group?.client_poi_id;
  const soldTo = group?.sold_to;
  const [isEditingGroup, setEditingGroup] = React.useState(false);
  const editButton = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => {
          setEditingGroup(true);
        }}
      >
        {<Edit />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <TextField
        // Use a key because when changing group,
        // sometimes the displayed value does not change
        key={`shipTo-${groupId}`}
        label={t('ship_to')}
        value={shipTo}
        disabled
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        placeholder="None"
        InputProps={{
          endAdornment: !shipTo && editButton
        }}
        name="shipTo"
        inputRef={register({
          validate: isActiveStep ? validateRequired : undefined
        })}
        error={errors.shipTo ? true : false}
        helperText={errors?.shipTo?.message}
      />
      <TextField
        key={`soldTo-${groupId}`}
        label={t('sold_to')}
        value={soldTo}
        disabled
        required
        fullWidth
        placeholder="None"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: !soldTo && editButton
        }}
        name="soldTo"
        inputRef={register({
          validate: isActiveStep ? validateRequired : undefined
        })}
        error={errors.soldTo ? true : false}
        helperText={errors?.soldTo?.message}
      />
      {isEditingGroup && (
        <Dialog open={isEditingGroup} onClose={() => setEditingGroup(false)} fullScreen>
          <CreateOrEditGroup
            id={groupId}
            editShiptoSoldtoMode
            onClose={() => setEditingGroup(false)}
          />
        </Dialog>
      )}
    </>
  );
};

export default AutoOrderFormPlantSelect;
