import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { IRootState } from 'config/store';
import { createProduct, updateProduct } from 'shared/reducers/productSlice';
import { IProduct } from 'shared/model/product.model';
import ProductForm, { IProductFormResponse } from './ProductForm';

export interface ICreateOrEditProductDialogProps {
  product?: IProduct;
  onSuccess?: Function;
}

const CreateOrEditProductDialog: React.FC<ICreateOrEditProductDialogProps> = ({
  product,
  onSuccess
}) => {
  const isNew = !product?.id;
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ product }: IRootState) => product.updateSuccess);

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true) {
      setOpen(false);
    }
  }, [updateSuccess]);

  const handleSubmit = (values: IProductFormResponse) => {
    const payload = {
      device_content: values.device_content,
      device_content_reference: values.device_content_reference,
      contract_number: values.contract_number,
      group_ids: values?.groups?.map(g => g.value)
    };
    if (isNew) {
      dispatch(createProduct({ ...payload }));
    } else {
      dispatch(updateProduct({ ...payload, id: product.id }));
    }
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
        {isNew ? <AddIcon /> : <EditIcon />}
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <ProductForm product={product} onSubmit={handleSubmit} onCancel={toggleDialog} />
      </Dialog>
    </>
  );
};

export default CreateOrEditProductDialog;
