import { IRootState } from 'config/store';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Dialog, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'shared/widgets/dataTable';
import { IDevice } from 'shared/model/device.model';
import { IDataTableColumn } from 'react-data-table-component';
import { Alert } from '@material-ui/lab';
import { fetchDevices, updateDevice, updateDevicesWithGroup } from 'shared/reducers/devicesSlice';
import DeviceForm, { IDeviceFormResponse } from 'modules/devices/dialogs/DeviceForm';
import Loading from 'shared/widgets/loading';
import validateRequired from 'shared/widgets/form/validateRequired';
import combineValidates from 'shared/widgets/form/combineValidates';
import { GroupAutoOrder } from 'shared/model/autoOrder.model';
import { AutoOrderFormResponse } from './AutoOrderForm';
import { usePrevious } from 'shared/utils/react-utils';

const AutoOrderFormSilosSelect = ({
  isNew,
  editGroupAutoOrder,
  isActiveStep
}: {
  isNew: boolean;
  editGroupAutoOrder?: GroupAutoOrder;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const form = useFormContext<AutoOrderFormResponse>();
  const groupId = form.watch('groupId')?.value;
  const shipTo = form.watch('shipTo');
  const soldTo = form.watch('soldTo');
  const group = useSelector(({ group }: IRootState) =>
    group.groups.find(g => g.group_id === groupId)
  );

  const [deviceToUpdate, setDeviceToUpdate] = useState<IDevice>();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const allDevices = useSelector(({ devices }: IRootState) => devices.devices);

  const devices = useMemo(
    () => allDevices.filter(d => d.farm_id === groupId),
    [groupId, allDevices]
  );

  const selectedDevices = devices.filter(d => selectedDeviceIds.includes(d.device_id));
  const isMissingDeviceContents = selectedDevices.some(device => device.device_content_id === null);

  const existingAutoOrderDeviceIds = editGroupAutoOrder
    ? editGroupAutoOrder.device_auto_orders.map(deviceAutoOrder => deviceAutoOrder.device_id)
    : [];

  // We want to select all rows by default, but depend on the callback from
  // react-data-table-comp@onent. So we do it only on the first load and
  // keep track of it.
  const shouldProvideDefaultSelection = useRef(true);
  const previousGroupId = usePrevious(groupId);
  if (previousGroupId !== groupId) {
    // When the group changed, we want to reselect all rows by default
    shouldProvideDefaultSelection.current = true;
  }

  // Save selection to form
  useEffect(() => {
    form.register('selectedDeviceIds', {
      validate: combineValidates(
        isActiveStep && validateRequired,
        isActiveStep &&
          (() => {
            return !isMissingDeviceContents || t('missing_infos_selected_silos');
          })
      )
    });
  }, [form, isActiveStep, isMissingDeviceContents, t]);

  useEffect(() => {
    form.setValue('selectedDeviceIds', selectedDeviceIds);
    const autoOrders = form.getValues('autoOrders');
    if (!autoOrders) return;

    // remove all auto-orders that are not in selectedDeviceIds
    Object.keys(autoOrders).forEach(deviceId => {
      if (!selectedDeviceIds.includes(deviceId)) {
        delete autoOrders[deviceId];
      }
    });
    form.setValue('autoOrders', autoOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceIds]);

  const columns: IDataTableColumn<IDevice>[] = useMemo(() => {
    return [
      {
        id: 'device_name',
        selector: row => row.device_name,
        name: t('silo_plural'),
        grow: 2
      },
      {
        selector: row => row.capa_max,
        name: t('capacity'),
        sortable: true,
        format: row => t('number_workspace_filling_unit', { value: row.capa_max }),
        grow: 1
      },
      {
        selector: 'device-content',
        format: row => {
          if (row.deviceContent?.device_content) {
            return row.deviceContent.device_content;
          } else if (selectedDeviceIds.includes(row.device_id)) {
            return (
              <Chip
                variant="outlined"
                icon={<AddIcon />}
                size="small"
                label={t('add_content')}
                color="secondary"
                onClick={() => setDeviceToUpdate(row)}
              />
            );
          } else {
            return '';
          }
        },
        name: t('device_content_silo_industry'),
        sortable: true,
        grow: 3
      },
      {
        selector: 'device-content-reference',
        format: row => row.deviceContent?.device_content_reference ?? '',
        name: t('reference'),
        sortable: true,
        grow: 1.5
      }
    ];
  }, [t, selectedDeviceIds]);

  return (
    <Fragment>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <TextField
            label={t('plant')}
            value={group?.group_name ?? ''}
            disabled
            placeholder="None"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <TextField
            label={t('ship_to')}
            value={shipTo || ''}
            disabled
            InputLabelProps={{ shrink: true }}
            placeholder="None"
          />
        </Grid>

        <Grid item>
          <TextField
            label={t('sold_to')}
            value={soldTo || ''}
            disabled
            placeholder="None"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Box p={1}>
        <DataTable
          key={groupId} // Remount the table when the group changes, to reselect all rows by default
          noHeader
          selectableRows
          onSelectedRowsChange={state => {
            setSelectedDeviceIds(state.selectedRows.map(row => row.device_id));
          }}
          selectableRowSelected={row => {
            if (shouldProvideDefaultSelection.current === true) {
              // Hack: after all rows are selected, keep track of it
              setTimeout(() => {
                shouldProvideDefaultSelection.current = false;
              }, 0);
              if (!isNew) {
                // todo : select auto order devices
                return existingAutoOrderDeviceIds.includes(row.device_id);
              } else {
                // Select all rows by default
                return true;
              }
            } else {
              // Issue: react-data-table-component loses track of selected rows
              // when underlying data changes (after a devices update). We need to restore it
              return selectedDeviceIds.includes(row.device_id);
            }
          }}
          columns={columns}
          data={devices}
          defaultSortField="device_name"
          defaultSortAsc={false}
          paginationPerPage={20}
        />
        {form.errors.selectedDeviceIds && (
          <Alert severity="error">{form.errors.selectedDeviceIds.message}</Alert>
        )}
      </Box>

      <DeviceContentDialog
        device={deviceToUpdate}
        onClose={() => setDeviceToUpdate(undefined)}
        onUpdated={() => {
          setDeviceToUpdate(undefined);
          form.triggerValidation();
        }}
      />
    </Fragment>
  );
};

const DeviceContentDialog = ({
  device,
  onClose,
  onUpdated
}: {
  device?: IDevice;
  onClose: () => void;
  onUpdated: () => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: IDeviceFormResponse) => {
    setIsLoading(true);
    const payload: Partial<IDevice> = {
      device_id: device?.device_id,
      device_content_id: values.device_content?.value ?? null
    };
    await dispatch(updateDevice(payload));
    await dispatch(fetchDevices());
    await dispatch(updateDevicesWithGroup());
    setIsLoading(false);
    onUpdated();
  };

  if (!device) {
    return null;
  }

  return (
    <Dialog open={!!device} onClose={onClose} maxWidth="lg">
      {isLoading ? (
        <Box p={4}>
          <Loading />
        </Box>
      ) : (
        <DeviceForm device={device} onSubmit={handleSubmit} onCancel={onClose} editContentOnly />
      )}
    </Dialog>
  );
};

export default AutoOrderFormSilosSelect;
