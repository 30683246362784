import { max, Moment } from 'moment';
import { IAlert } from './alert.model';
import { groupBy } from 'lodash';
import moment from 'moment';

export interface AutoOrder extends IAlert {
  // Group Infos
  group_id: string;
  group_name: string;
  contract_number: string | null;
  ship_to: string | null;
  sold_to: string | null;

  // Device Infos
  device_content: string | null;
  device_content_reference: string | null;
  device_id: string;
  device_name: string;
  device_reference: string;
  capa_max: number | null;
  is_combined: boolean;

  // Alert infos
  alert_id: string;
  alert_name: string;
  is_active: boolean;
  data_type: 'level_t' | 'missingWeight';
  max_value: number | null;
  min_value: number | null;
  created_at: Moment;
  created_by_id: string | null;
  last_autoorder_date: Moment | null;
  custom_integration: CustomIntegration;
  notification_strategy_names: ('email' | 'push')[];
  recipients_for_notifications_ids: string[];
}

export interface DeviceAutoOrder {
  // Device infos
  device_name: string;
  device_id: string;
  device_reference: string;
  device_content: string | null;
  device_content_reference: string | null; // material number
  capa_max: number | null;
  is_combined: boolean;

  // Auto order infos
  is_active: boolean;
  last_autoorder_date: Moment | null;
  alerts: AutoOrder[];
}

export interface GroupAutoOrder {
  // Group infos
  group_id: string;
  group_name: string;
  sold_to: string | null;
  ship_to: string | null;

  // Auto order infos
  is_active: boolean;
  last_autoorder_date: Moment | null;
  device_auto_orders: DeviceAutoOrder[];
  notification_strategy_names: ('email' | 'push')[];
  recipients_for_notifications_ids: string[];
}

export interface CustomIntegration {
  nbOfOrder: number;
  plantNo?: number;
  materialNo?: string;
  shippingReceivingPoint?: string;
  soldToVendor?: string;
}

export interface AutoOrderParams {
  group_id: string;
  device_reference: string;
  page: number;
  page_size: number;
}

export type AutoOrderResponse = Omit<AutoOrder, 'created_at' | 'last_autoorder_date'> & {
  created_at: string;
  last_autoorder_date: string | null;
};

// Signature of function depending on parameters
function parseAutoOrderResponse(autoOrder: AutoOrderResponse): AutoOrder;
function parseAutoOrderResponse(autoOrders: AutoOrderResponse[]): AutoOrder[];
function parseAutoOrderResponse(
  autoOrder: AutoOrderResponse | AutoOrderResponse[]
): AutoOrder | AutoOrder[] {
  const parse = (autoOrder: AutoOrderResponse): AutoOrder => ({
    ...autoOrder,
    created_at: moment(autoOrder.created_at),
    last_autoorder_date: autoOrder.last_autoorder_date
      ? moment(autoOrder.last_autoorder_date)
      : null
  });

  if (Array.isArray(autoOrder)) {
    return autoOrder.map(parse);
  } else {
    return parse(autoOrder);
  }
}

export function groupAutoOrdersByGroup(autoOrders: AutoOrder[]): GroupAutoOrder[] {
  const deviceAutoOrders = groupAutoOrdersByDevice(autoOrders);

  return Object.entries(groupBy(deviceAutoOrders, autoOrder => autoOrder.alerts[0]?.group_id)).map(
    ([group_id, deviceAutoOrders]) => {
      const {
        group_name,
        ship_to,
        sold_to,
        notification_strategy_names,
        recipients_for_notifications_ids
      } = deviceAutoOrders[0].alerts[0];

      const group: GroupAutoOrder = {
        group_id,
        group_name,
        ship_to,
        sold_to,
        is_active: deviceAutoOrders.some(deviceAutoOrder => deviceAutoOrder.is_active),
        last_autoorder_date: getLastOrderDate(deviceAutoOrders),
        device_auto_orders: deviceAutoOrders,
        notification_strategy_names,
        recipients_for_notifications_ids
      };

      return group;
    }
  );
}

function groupAutoOrdersByDevice(autoOrders: AutoOrder[]): DeviceAutoOrder[] {
  return Object.entries(groupBy(autoOrders, order => order.device_id)).map(
    ([device_id, alerts]) => {
      const {
        device_name,
        device_reference,
        device_content,
        device_content_reference,
        capa_max,
        is_combined
      } = alerts[0];

      return {
        device_id,
        device_name,
        device_reference,
        device_content,
        device_content_reference,
        capa_max,
        is_combined,
        is_active: alerts.some(alert => alert.is_active),
        last_autoorder_date: getLastOrderDate(alerts),
        alerts
      };
    }
  );
}

function getLastOrderDate(items: Array<{ last_autoorder_date: Moment | null }>): Moment | null {
  const lastOrderDates: Moment[] = items
    .filter(({ last_autoorder_date }) => Boolean(last_autoorder_date))
    .map(({ last_autoorder_date }) => moment(last_autoorder_date));

  return lastOrderDates.length === 0 ? null : max(lastOrderDates);
}

export { parseAutoOrderResponse };
