import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

type SelectMinProps = {
  isActive?: boolean;
} & Partial<TextFieldProps>;

const SelectMin = (props: SelectMinProps) => {
  const { isActive = true, ...rest } = props;
  const { t } = useTranslation();
  const form = useFormContext();
  const dataType = form.watch<string, string>('data_type');

  return (
    <TextField
      id="min_value"
      label={t('min_val')}
      type="number"
      name="min_value"
      inputProps={{
        step: 'any',
        min: 0
      }}
      inputRef={form.register({
        validate: value => {
          if (isActive) {
            if (value.length === 0) {
              return <Trans i18nKey="required_field">Required Field</Trans>;
            } else if (value < 0) {
              return <Trans i18nKey="positive_integer_value">Required Field</Trans>;
            }
            if (
              value?.length > 0 &&
              (dataType.value === 'level_t' || dataType.value === 'missingWeight')
            ) {
              if (!/^\d+(\.\d{1})?$/.test(value))
                return <Trans i18nKey="alert_form_one_decimal_error">Required Field</Trans>;
            }
            if (
              value?.length > 0 &&
              dataType.value !== 'level_t' &&
              dataType.value !== 'missingWeight'
            ) {
              if (!Number.isInteger(Number(value))) {
                return <Trans i18nKey="alert_form_min_or_max_integer_error">Required Field</Trans>;
              }
            }
          }
          return true;
        }
      })}
      error={form.errors.min_value ? true : false}
      helperText={form.errors.min_value && form.errors.min_value.message}
      {...rest}
    />
  );
};

export default SelectMin;
