import {
  Button,
  createStyles,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import React, { useCallback, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomIntegration, GroupAutoOrder } from 'shared/model/autoOrder.model';
import AutoOrderFormPlantSelect from './AutoOrderFormPlantSelect';
import AutoOrderFormSilosSelect from './AutoOrderFormSilosSelect';
import AutoOrderFormTriggeringSetup from './AutoOrderFormTriggeringSetup';
import { useDispatch, useSelector } from 'react-redux';
import { updateGroupAutoOrders } from 'shared/reducers/autoOrdersSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    }
  })
);
type uuid = string;

export interface AutoOrderFormResponse {
  groupId: uuid;
  shipTo: string;
  soldTo: string;
  emailNotification: boolean;
  emailRecipients?: string[];
  selectedDeviceIds: string[];
  autoOrders: {
    [device_id: uuid]: Array<{
      alert_id: uuid;
      alert_name: string;
      is_active: boolean;
      data_type: 'level_t' | 'missingWeight';
      min_value?: number;
      max_value?: number;
      custom_integration: CustomIntegration;
    }>;
  };
}

function StepContainer({
  activeStep,
  step,
  children
}: {
  activeStep: number;
  step: number;
  children: React.ReactNode;
}) {
  return <Box display={activeStep === step ? 'block' : 'none'}>{children}</Box>;
}

type AutoOrderFormProps = {
  editGroupAutoOrder?: GroupAutoOrder;
  onClose: () => void;
};

function AutoOrderForm({ editGroupAutoOrder, onClose }: AutoOrderFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updating = useSelector(({ autoOrder }: IRootState) => autoOrder.updating);
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = [t('select_plant'), t('select_silos'), t('auto_order_form_step_setup')];

  const isNew = !editGroupAutoOrder;

  const defaultAutoOrders: AutoOrderFormResponse['autoOrders'] =
    editGroupAutoOrder?.device_auto_orders.reduce((acc, deviceAutoOrder) => {
      return {
        ...acc,
        [deviceAutoOrder.device_id]: deviceAutoOrder.alerts.map(alert => {
          return {
            alert_id: alert.alert_id,
            alert_name: alert.alert_name,
            is_active: alert.is_active,
            data_type: alert.data_type,
            min_value: alert.min_value ?? undefined,
            max_value: alert.max_value ?? undefined,
            custom_integration: alert.custom_integration
          };
        })
      };
    }, {} as AutoOrderFormResponse['autoOrders']) ?? {};

  const form = useForm<AutoOrderFormResponse>({
    mode: 'onChange',
    defaultValues: {
      groupId: editGroupAutoOrder?.group_id,
      shipTo: editGroupAutoOrder?.ship_to ?? undefined,
      soldTo: editGroupAutoOrder?.sold_to ?? undefined,
      emailNotification: Boolean(editGroupAutoOrder?.notification_strategy_names.includes('email')),
      emailRecipients: editGroupAutoOrder?.recipients_for_notifications_ids,
      selectedDeviceIds: editGroupAutoOrder?.device_auto_orders.map(d => d.device_id) ?? [],
      autoOrders: defaultAutoOrders
    }
  });

  const handleNext = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  console.log('form', form.getValues());

  const onSubmit = useCallback(
    async (formValues: AutoOrderFormResponse) => {
      await dispatch(
        updateGroupAutoOrders(createAutoOrdersPayloadFromForm(formValues), () => {
          onClose();
        })
      );
    },
    [dispatch, onClose]
  );

  return (
    <FormContext {...form}>
      <form
        id="auto-order-form"
        className={classes.root}
        onSubmit={form.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Box className={classes.title}>
          <Typography variant="h5">{t('auto_order_form_configure')}</Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item className={classes.content}>
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box pt={2}>
              <StepContainer activeStep={activeStep} step={0}>
                <AutoOrderFormPlantSelect
                  editGroupAutoOrder={editGroupAutoOrder}
                  isNew={isNew}
                  isActiveStep={activeStep === 0}
                />
              </StepContainer>
              <StepContainer activeStep={activeStep} step={1}>
                <AutoOrderFormSilosSelect
                  editGroupAutoOrder={editGroupAutoOrder}
                  isNew={isNew}
                  isActiveStep={activeStep === 1}
                />
              </StepContainer>
              <StepContainer activeStep={activeStep} step={2}>
                <AutoOrderFormTriggeringSetup
                  editGroupAutoOrder={editGroupAutoOrder}
                  isNew={isNew}
                  isActiveStep={activeStep === 2}
                />
              </StepContainer>
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button onClick={onClose} startIcon={<CancelIcon />} variant="contained">
            <Hidden xsDown>{t('cancel')}</Hidden>
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          <Button
            color="primary"
            variant="contained"
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
            disabled={updating || activeStep === 0}
          >
            <Hidden xsDown>{t('prev')}</Hidden>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}
            disabled={updating || activeStep === steps.length - 1}
          >
            <Hidden xsDown>{t('next')}</Hidden>
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={updating || activeStep !== steps.length - 1}
            variant="contained"
          >
            <Hidden xsDown>{t('save')}</Hidden>
          </Button>
        </Box>
      </form>
    </FormContext>
  );
}

function createAutoOrdersPayloadFromForm(formValues: AutoOrderFormResponse) {
  const {
    groupId: { value: group_id },
    emailRecipients,
    emailNotification,
    autoOrders
  } = formValues;

  return Object.entries(autoOrders).flatMap(([device_id, autoOrders]) => {
    return autoOrders.map(alert => {
      return {
        alert_id: alert.alert_id,
        is_active: alert.is_active ?? true, // mettre à true par défaut pour les create
        alert_name: alert.alert_name,
        data_type: alert.data_type,
        min_value: alert.min_value ? Number(alert.min_value) : undefined,
        max_value: alert.max_value ? Number(alert.max_value) : undefined,
        custom_integration: alert.custom_integration,
        group_id,
        device_id,
        notification_strategy_names: (emailNotification ? ['email'] : []) as ('email' | 'push')[],
        recipients_for_notifications_ids: emailRecipients ?? []
      };
    });
  });
}

export default AutoOrderForm;
